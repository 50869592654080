
body{
  
  font-family: 'Open Sans', 'Helvetica Neue', Arial, sans-serif;
}
body {
  font-family: 'Open Sans', 'Helvetica Neue', Arial, sans-serif;
  margin: 0;
  padding: 0;
  height: 100%;
}

#container-check {
  min-height: 100%;
  height: 100%;
  position: relative;
}
.video{
  width:80%;
  text-align:center;
  margin-left:10%;
}
.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.btn.focus, .btn:focus {
  outline: 0 !important;
  box-shadow: 0 0 0 0.2rem rgba(245, 247, 250, 0) !important;
}
   
   .margin-left-top{
     margin-top:20px;
     margin-left :30px;
   } 
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}
.fixed-top {
 background-color: white;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}
.powered-color{
  color:white;
  font-size:20px;

}
.powered-color:hover{
  color:white;
}
.clr-default{
  cursor:default;
}
.section-heading {
  margin-top: 0;
}
.text-uppercase {
  text-transform: uppercase!important;
}
.note
  {
    font-size: 13px;
    font-weight: 600;
    padding-top:5px;
    color: #a2a2a2;
}
.hr{
  border-bottom: 2px solid white;
  margin: 0 auto;
    padding-top: 7px;
    width:4%;
}

.para{
  color: #bdbdbd;

  font-weight: 400;
  font-family:co-headline,sans-serif;
  /* font-family: adobe-clean Helvetica,sans-serif; */
}
.container-fluid {
  width: 100%;
  padding-right: 30px;
  padding-left: 30px;
  margin-right: auto;
  margin-left: auto;
}
.h5-div-style{
  font-weight: bold;
  color: #3EA99F;
 
  padding-bottom: 10px;
}
.background{
  /* background-image: url(assests/images/bg3.png); */

  padding-top: 23px;
  padding-bottom: 18px;
}

.image{
width:70px;
height:50px;
}

 .banner-text{
 padding: 30px;
 margin-top:40px;
 }
 .paragraph{
 
      color: white;
      font-weight: 400;
      font-size: 1.25rem;
 
 }
 .h3-div {
  margin-bottom: 20px;
  color: white;
  font-weight: bold;
  font-size: 2em;
  margin-bottom:20px;
}



.affix {
  top: 0;
  left:0;

padding-left:20px;
  border-width: 4px;
    border-style: solid;

    border-image: url(assests/images/border.png) round 1;
    /* background-image: url(assests/images/terms-header.png); */
    border-top: 0;
    border-right: 0;
    border-left: 0;
    padding-top: 10px;
    padding-bottom: 12px;
   
  width: 100%;
  z-index: 9999 !important;
  
background: #515151; /* Old browsers */
background: -moz-linear-gradient(left,  #515151 1%, #ffffff 99%); /* FF3.6-15 */
background: -webkit-linear-gradient(left,  #515151 1%,#ffffff 99%); /* Chrome10-25,Safari5.1-6 */
background: linear-gradient(to right,  #515151 1%,#ffffff 99%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#515151', endColorstr='#ffffff',GradientType=1 ); /* IE6-9 */


}
video::-webkit-media-controls {
  display:none !important;
}

.affix + .container-fluid {
  padding-top: 70px;
}

.section-border-bottom {
  border-image: url(assests/images/border.png);
  padding-bottom: 30px;
  cursor: default;
  box-shadow: 2px 0px 6px #b5b4b4;
}

.footer{
position: absolute;;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1rem;
  background-image: url(assests/images/terms-header.png);

}
.font-size-footer{
  font-size:16px;
}

.iframe-div{
  width: 100%;
  height: 510px;
 margin-bottom: 7px;
 box-shadow: 5px 5px 10px #1c1c1c;
    border-radius: 10px;
}
.card-img-top {
  opacity: 0.8 ;
  width: 100%;
  height: 99px;
  border-top-left-radius: calc(.25rem - 1px);
  border-top-right-radius: calc(.25rem - 1px);

}
hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-bottom: 1px solid white;
}
.tick-icon{
  padding-right:10px;
}
.card-img-top-last {
  width: 100%;
  /* background: inherit; */
  /* background-color: rgba(50,69,86,.619607843137255); */
  /* box-sizing: border-box; */
  /* border-width: 0; */
  /* border-style: solid; */
  border-radius: 6px;
  /* font-weight: 400; */
  /* box-shadow: 5px 5px 10px #1c1c1c; */
  height: 150px;
  text-align:center;  
}

.pt-6 {
  padding-top: 6rem!important;
}

.border-to-cards{
  border-radius: 10px;
  border:3px solid white;
  box-shadow: 5px 5px 10px #1c1c1c;
  text-align:center;  
}
.border-to-cards:hover {
  border:3px solid black;
}

.card-img-top-last1 {
  width: 100%;
   border-radius: 6px;
 height: 113px;
  text-align:center;  

}

.logo-style
  {
   margin-left: -34px;
    margin-top: 20px;
    height: 30px;

}

.icon.back{
  margin-top: -3px;
    font-weight: 100;
    font-size: 31px;
    margin-left: 10px;
}
    
.padding-bottom{
  padding-bottom: 12px;
}
.experience-name{

  font-size: 16px;
  font-weight: 500;
cursor:default;
}
.author-name{
  
      font-size: 13px;
      font-weight: 700;
      padding-top: 3px;
      color: gray;
 
}
.views{
  
      margin-top: -10px;
      font-size: 13px;
      color: gray;
      cursor:default;
  
}
.span-views{
  padding-left: 5px;
  cursor:default;
}
.card-img-top:hover{
  opacity:1;
  cursor: pointer;
  -webkit-transform: scale(2,2);
  -ms-transform: scale(1, 1);
  transform: scale(1.1, 1.1);
  transition-duration: 0.3s;
  -webkit-transition-duration: 0.3s; 
  box-shadow: 4px 4px 5px #28374778;
  z-index: 1;
}



.card-img-top-last:hover{
  opacity:1;
  cursor: pointer;
  -webkit-transform: scale(2,2);
  -ms-transform: scale(1, 1);
  transform: scale(1.1, 1.1);
  transition-duration: 0.3s;
  -webkit-transition-duration: 0.3s; 
  box-shadow: 4px 4px 5px #28374778;
  z-index: 1;
}

.overlay {
  position: absolute;
  top:-1px; 
  bottom: -4px;
  left: 0;
  right: 0;
  height:114px;
  max-width: 103% !important;
  opacity: 0;
  transition: .5s ease;
  background-color:	#000000;
  border: 3px solid #000000;
}
.overlay-trending {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  
  width: 100%;
  opacity: 0;
  transition: .5s ease;
  background-color: #000000;
  border: 3px solid #000000;
 
}



.container-img{
  position: relative;
  text-align: center;
  color: white;
  margin-bottom: 6px;
 
}
.container-img1{
  position: relative;
  text-align: center;
  color: white;
  margin-bottom:10px;

}

.card-img{
  position: relative;
  text-align: center;
  color: white;
 
 border: 3px solid white;
}
.card-img:hover {
  border: 3px solid #404040;
}

.padding-col-3{
    padding: 2px;
}
.container-img:hover .overlay {
  opacity: 0.7;

}
.container-img1:hover .overlay {
  opacity: 0.7;

}
.container-img:hover .overlay-trending {
  opacity: 0.7;

}



.text {
  position: absolute;
  font-size: 14px;
  color: #fff;
  min-height: 118px;
  max-height: 118px;
  padding-right: 20px;
}

.h6-text-color{
    color: 	#696969;
    cursor:default;
}

.imgavatar{
  border-radius: 50px;
    width:28px;
    height:28px;
   
}
.avatar-div-margin{
  margin-top: 2px
}
.paragraph-for-name{
  
      font-size: 13px;
      font-weight: 900;
      padding-top: 6px;
      margin-left: 10px;
      cursor:default;
  }

  .v-header{
    height:100vh;
    display:flex;
    align-items:center;
    color:#fff;
  }
  
  .container{
    max-width:960px;
    padding-left:1rem;
    padding-right:1rem;
    margin:auto;
    text-align:center;
  }
  
  .fullscreen-video-wrap{
    position:absolute;
    top:0;
    left:0;
    width:100%;
    height:100vh;
    overflow:hidden;
  }
  
  .fullscreen-video-wrap video{
    min-height:100%;
    min-width:100%;
  }
  
  .header-overlay{
    height:100vh;
    position: absolute;
    top:0;
    left:0;
    width:100vw;
    z-index:1;
    background: rgba(0,0,0,1);
    opacity:0.7;
  }
  
  .header-content{
    z-index:2;
  text-align:left;
position:absolute;

  }


  
  .header-content h1{
    font-size:50px;
    margin-bottom:0;
  }
  
  .header-content p{
    font-size:1rem;
    display:block;
   /* padding-bottom:2rem; */
  }
  
 
  
  .section{
    padding:20px 0;
  }
  
  .section-b{
    background:#333;
    color:#fff;
  }
  
  @media(max-width:960px){
    .container{
      padding-right:3rem;
      padding-left:3rem;
    }
  }

/* .icon-view{
 font-size:22px;

  color: gray;
} */
.view-text-style{
  font-size: 12px;
  padding-left: 7px;
}
.view-text-count{
  font-size: 14px;
  font-weight: 700;
  margin-left: 1px;
cursor: default;
}
.padding-left-countdiv{
  padding-left: 5px;
}
.padding-left-margin{
  padding-left:30px;
}
.embed-right,.embed-left
  {
       padding-left: 7px;
       color:gray;
       
  }
 
.col1-embed-icon{
  font-size:22px;
  color: gray;
  font-weight: 900;
}
.heart-icon{
  color:red;
  font-size: 22px;
  margin-top:-8px;
}
.heart-icon:hover{
  opacity:1;
  cursor: pointer;
  -webkit-transform: scale(1,1);
  -ms-transform: scale(1, 1);
  transform: scale(1.2, 1.2);
  transition-duration: 0.3s;
  -webkit-transition-duration: 0.3s; /* Safari */
  z-index: 1;
}


.margin-top-left-icon{
  margin-top: 5px;
  font-weight: 900;
  color:gray;
}


.trending-img-style{
  width: 100%;
  height: 100px;

}

.h6-trending-padding{
  padding-bottom: 20px;
  padding-top:10px;
  font-size:20px;

}
.see-all-div{
  text-align: right;
  font-size: 13px;
  font-weight: 700;
  padding: 10px;
}
.experience-name-trending{
  font-size: 16px;
      padding-top: 3px;
      text-align: center;
      color: #404040;
      cursor:default;
 
}
.btn1 {
    color: #000000;
    background-color: white;
    border-color: #000000;
}
.Shadow{
  box-shadow: 5px 5px 10px 	#696969;
}
.Shadow1{
  box-shadow: 5px 5px 10px 	#b7aeae;
}


.btn {
  display: inline-block;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border: 1px solid transparent;
  padding: .375rem .75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: .25rem;
  transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}
.btn {
  font-weight: 700;
  text-transform: uppercase;
  border: none;
  border-radius: 300px;
  font-family: 'Open Sans', 'Helvetica Neue', Arial, sans-serif;
}
.clr-pointer{
  cursor: pointer;
}
.clr-default{
  cursor:default;
}
.btn-info {
  margin-top: 4px;
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
  height: 40px;
  border-radius: .25rem;
  margin-left: 15px;
}
.btn-info1 {
  margin-top: 4px;
  color: #fff;
  background-color: #2b7d2b;
  border-color: #2b7d2b;
  height: 37px;
  border-radius: .25rem;
  margin-left: 15px;
  margin-bottom: 10px;
}
.margin-back-button{
  margin-top:20px;
  margin-bottom:7px;
}
.margin-input-search-div{
  margin-left: 93px;
 
}

.icons-span-style{
  width: 20px;
  max-height: 20px !important;

}



.margin-top{
  margin-top:10px;
  margin-bottom:12px;
}

 .loader{
       background-color: black !important;
    opacity: 0.6;
    position: fixed;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    z-index: 9999;
    background: url(assests/images/loader1.svg) 50% 50% no-repeat;
 }
 .margin-top-embed{
   margin-top: -4px;
 }
 .margin-top-View{
 margin-top: -2px; 
}
.tooltip1 {
  position: relative;
  
}
.font{
  font-size: 35px;
}
.play-button{
 margin-top:80px;
}

.tooltip1 .tooltiptext1 {
  visibility: hidden;
  width: 90px;
  text-align:center;
  background-color: #222222;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 6px 0;

  /* Position the tooltip */
  position: absolute;
  z-index: 1;
  top: 80%;
  left: 50%;
  margin-left: -40px;
 
}
.tooltip2 {
  position: relative;
  
}

.tooltip2 .tooltiptext2 {
  visibility: hidden;
  width: 90px;
  text-align:center;
  background-color: #222222;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 6px 0;

  /* Position the tooltip */
  position: absolute;
  z-index: 1;
  top: 100%;
  left: 50%;
  margin-left: -94px;
  
}
.category-padding{
  padding-top: 5px;
  
  color: #3EA99F;
  font-family: 'Open Sans', 'Helvetica Neue', Arial, sans-serif;
}
.margin-left-search{

  margin-left:35px;
}
.input-box{
  display: block;
  width: 100%;
  height: 42px;
  margin-top: 3px;
  padding: .375rem .75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: .25rem;
  transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}
.copy-button {
  font-weight: bold;
  color: #fff;
  background-color: #0062cc;
  border-color: #005cbf;
  padding: 2px 10px;
  border-radius: 5px;
}


.logoimg{
  height: 55px;
}
#page-container {
  position: relative;
  min-height: 100vh;
}
.main-logo{
  padding-top: 30px;
  height:90%;
  margin-left: 30px;
}
.font-weight-style{
  font-weight: 400;
  font-size: 42px;
  font-family:co-headline,sans-serif;
}
.font-weight-style1{
  font-weight: 100;
}
#content-wrap {
  padding-bottom: 3rem;   
}

.a-color{
  color:white;
}

#footer {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 1.5rem;            /* Footer height */
  
 }

.a-color:hover{
  color:white;

}
.free-style{
  color: #3bef3b;
  font-size: 30px;
  font-weight: bold;
}
.free-style1{
  color: #3bef3b;
  font-size: 42px;
  font-weight: bold;
}

  .logoimg1{
    width:200px;
    height:50px;
    font-weight: 700;
      text-transform: uppercase;
      color: #F05F40;
      font-family: 'Open Sans', 'Helvetica Neue', Arial, sans-serif;
      margin-left: -60px;
    }

  .lastcomponent-bottom-border{
    border-image: url(assests/images/border.png) round 1;
  }

  .LinkPage {
    height: 100%;
    min-height: 100%;
  }

  .main-div {
    border-width: 4px;
    border-style: solid;
    border-image: url(assests/images/border.png) round 1;
    /* background-image: url(assests/images/terms-header.png); */
    border-top: 0;
    border-right: 0;
    border-left: 0;
    padding-top: 23px;
    padding-bottom: 18px;
    background-color: white;
    /* z-index: 10300; */
  }

  .lastcomponent-bottom-border{
    border-image: url(assests/images/border.png) round 1;
  }
  
  .main-div-footer{
    border-width: 4px;
    border-style: solid;
 
    /* background-image: url(assests/images/terms-header.png); */
    border-top: 0;
    border-right: 0;
    border-left: 0;
    padding-top: 23px;
    padding-bottom: 18px;
   
}

* {
  box-sizing: border-box;
}



.content {

  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  color: #f1f1f1;
  width: 100%;
  padding: 20px;
}
  
  .margin-left-search-button{
    margin-left:-14px;
  }

.tooltip1:hover .tooltiptext1 {
  visibility: visible;
}

::-webkit-scrollbar-track
{
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    background-color: #F5F5F5;
}

::-webkit-scrollbar
{
    width: 6px;
    background-color: #F5F5F5;
}

::-webkit-scrollbar-thumb
{
    background-color: #3EA99F;
}

.max-widht-text {
  max-width: 250px;
}
.padding-left-right
{
 font-size: 16px;
  font-family:co-headline,sans-serif;
  padding-left: 25px;
  padding-right: 25px;
}
.try-experience{
  background-color: #2b7d2b;
}
.custom-control-input:checked~.custom-control-label::before {
  color: #fff;
  border-color: #138496 !important;
  background-color: #138496 !important;
}

.hidden {
  display: none;
   -webkit-transition:height, 0.5s linear;
   -moz-transition: height, 0.5s linear;
   -ms-transition: height, 0.5s linear;
   -o-transition: height, 0.5s linear;
   transition: height, 0.5s linear;
  }
    .hiddenOpen {
      display: block;
     -webkit-transition:height, 0.5s linear;
    -moz-transition: height, 0.5s linear;
    -ms-transition: height, 0.5s linear;
    -o-transition: height, 0.5s linear;
    transition: height, 0.5s linear;
    }

    @media (min-width: 320px) and (max-width: 480px) {
  
    .textcenter{
      text-align: center;
    }
      
    }
    @media only screen and (device-width: 768px) {
      /* For general iPad layouts */
      .container-img1 {
        margin-left: 37px;
        width: 80%;
        margin-bottom: 18px;
        
      }
    }

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.modal-content {
  border-radius: 0.7rem !important;
}



@media (min-width: 320px) and (max-width: 480px) {
  .font-size-footer {
    font-size: 12px;
}

  .font-weight-style{
    
    font-size:20px;
  }
  .h3-style{
    font-size:15px;
  }

.para {
 
  font-size: 16px;
}

.main-logo {
  height: 74%;
  margin-top: -9px;
}
.free-style1 {
 
  font-size: 24px;

}
.font{
  font-size:20px;
} 
.play-button {
  width: 40px;
  /* padding-top: 20px; */
}
.width-experizer{
  width: 45px;
}

}
@media only screen and (max-width: 480px)
 {
  .font-size-footer {
    font-size: 9px;
}
  
  .font-weight-style{
  
    font-size:18px;
  }
  .h3-style{
    font-size:14px;
  }

.para {
 
  font-size: 10px;
}
  
.font{
  font-size:25px;
}
.play-button {
  width: 40px;

  margin-top: 7px;
}
.width-experizer{
  width: 45px;
}
 }
 @media only screen 
 and (min-width : 320px)
 and (max-width : 812px) 
 and (orientation : landscape) {
  .font{
    font-size:20px;
  }
  .free-style {
    font-size: 27px;}

  .font-weight-style{
  
    font-size:14px;
  }
  .h3-style{
    font-size:14px;
  }

  .main-logo {
    height: 78%;
    margin-top: -16px;
}
.para {
 
  font-size: 10px;
}  
.free-style1 {
  font-size: 24px;

}
.btn-info1 {
 
  height: 31px;

}
.padding-left-right {
  font-size: 14px;
 
}

 }

 /* Icon made by Freepik */
.icon-width{
  
  width: 20px;
}
.share-color{
  color: gray;
}
.thumbnail-img{
  width:  100%;
  height: 506px;
  margin-bottom: 15px;
}


.Thumnail-style {
  position: relative;
  max-width:  100%;
  margin: 0 auto;
}

.Thumnail-style img {vertical-align: middle;}

.Thumnail-style .content {
  position: absolute;
  bottom: 0;
  top: 0;
  background: rgb(0, 0, 0);
  background: rgba(0, 0, 0, 0.5);
  color: #f1f1f1;
  width: 100%;
  height: 506px;
  padding: 32px;
}
.play-button{
width:80px;

}
.viewExp {
    flex: 1;
    justify-content: center;
    align-Items: center;
}
.play-tooltip-margin{
  margin-top: 50px;
}

.social-icon-padding{
  padding-right: 27px;
}
.margin-top-play{
  
    margin-top: 10px;

}


/*phone 5 landscape*/
@media only screen 
and (min-width : 320px)
and (max-width : 568px) 
and (orientation : landscape) {
  .main-logo {
    height: 70%;
    margin-top: -18px;
}
.free-style1 {
  font-size: 18px;

}
.font-weight-style {
  font-size: 13px;
  margin-top: -8px;
}
.margin-top-header{
  margin-top:30px;
}
.btn-success {
  margin-top: -5px;

}
.Thumnail-style .content {
 padding: 10px;
}

}